





import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class App extends Vue {
    private fileLocation = '/chimmney';

    private isAndroid = /(android)/i.test(navigator.userAgent);

    private isIOS = /(iphone|ipad|macintosh)/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

    created() {
      this.showAr();
    }

    showAr() {
      if (this.isAndroid) {
        const intent = `intent://arvr.google.com/scene-viewer/1.0?file=${window.location.protocol}//${window.location.hostname}${this.fileLocation}.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
        window.location.assign(intent);
      } else if (this.isIOS) {
        App.showIOSAR(`${window.location.protocol}//${window.location.hostname}${this.fileLocation}.usdz#allowsContentScaling=0`);
      }
    }

    private static showIOSAR(usdzSrc: string) {
      const anchor = document.createElement('a');
      anchor.setAttribute('rel', 'ar');
      anchor.appendChild(document.createElement('img'));
      anchor.setAttribute('href', usdzSrc);
      anchor.click();
    }
}
